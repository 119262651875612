import React from 'react';

const BackgroundWithText = () => {
  return (
    <div className="background-container">
      <div className="background-image">
        <h1 className="text-on-image">ConflictOfWar is coming soon....⚔️</h1>

      </div>
    </div>
  );
};

export default BackgroundWithText;
